.offer_exit {
	background-color: rgba(255, 255, 255, 0.8);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999999;
}

.offer_exit_container {
	background-image: url( '../images/background.png' );
	height: 350px;
	left: 50%;
	padding: 0 3rem;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 584px;
}

.offer_exit_container p {
	margin: 0;
}

.offer_exit_container p + p {
	margin: 2rem 0 0 0;
}

.offer_exit_form {
	display: block;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.offer_exit_form_text {
	color: #ffffff;
	display: block;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 42px;
	font-weight: bold;
	line-height: 1.25;
	margin: -0.5rem 0;
	position: relative;
}

.offer_exit_form_email_field {
	background: rgba(255, 255, 255, 0.6);
	border: #ffffff solid 2px;
	display: block;
	font-size: 0.875rem;
	font-weight: bold;
	letter-spacing: 1px;
	margin: 0 auto;
	padding: 0.75rem;
	text-align: center;
	width: 300px;
}

.offer_exit_form_button {
	background: #ffffff;
	border-radius: 10px;
	border: none;
	color: #ad3b48;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.5rem;
	font-weight: bold;
	padding: 0.5rem;
	width: 200px;
}

.offer_exit_confirmation {
	color: #ffffff;
	display: none;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.offer_exit_confirmation_text {
	color: #ffffff;
	display: block;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 42px;
	font-weight: bold;
	line-height: 1.25;
	margin: -0.5rem 0;
	position: relative;
}

.offer_exit_error_text {
	display: none;
	color: #ffffff;
	display: block;
	font-family: 'Roboto Slab', sans-serif;
	line-height: 0.25;
	margin: -0.5rem 0;
	position: relative;
	margin: 1rem !important;
}

.offer_exit_close {
	position: absolute;
	right: 0;
	top: 0;
}

.offer_exit_close_button {
	background: url( '../images/close.svg' );
	border: none;
	border-radius: 100%;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	height: 32px;
	padding: 0;
	text-indent: -999999px;
	width: 32px;
}
